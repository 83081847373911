import React, { Suspense } from 'react';
import {
    Stack,
    StackItem,
    Text,
    FontWeights,
    IStackTokens,
    IStackStyles,
    ITextStyles,
    IStackItemTokens,
    TextField,
} from "@fluentui/react";

import { PrimaryButton } from "@fluentui/react/lib/Button";
import { SessionListView, SearchListProps, listNameEnum } from "./session/sessionListView";
import { LayoutStyles } from "../styles/layout";
import { DateRangePicker } from '../dateRangePicker';
import VF from '../util/ValueFormatting';
import { BigIdSessionView } from '../sessions/bigIdSessionView';
import { ManifestSessionListView } from './session/manifestSessionListView';

const boldStyle: Partial<ITextStyles> = {
    root: { fontWeight: FontWeights.semibold },
};
const stackTokens: IStackTokens = { childrenGap: 15 };
const stackStyles: Partial<IStackStyles> = {
    root: {
        margin: "0 auto",
        color: "#605e5c",
        display: "flex",
        maxWidth: 1200,
    },
};
const calendarWrapperTokens: IStackItemTokens = { padding: '4px 2px 2px' };
const stackItemStyles: Partial<IStackStyles> = {
    root: {
        width: 300
    },
};

export interface IIngestionViewProps {
    ingestionType: string
 }

 enum IngestionType {
    INGESTION = 'ingestion',
    BIGID = 'bigid',
    MANIFEST = 'manifest'

  }
interface IIngestionViewState {
    documentIdFilter: string;
    startTime: string;
    endTime: string;
    externalDataFilter: string;
    cV: string;
    enableSearch: number;
    bigId:string;
    cvBybigId:string;
    sessionFilter:string
}

export class IngestionView extends React.Component<
    IIngestionViewProps,
    IIngestionViewState
> {
    constructor(props: IIngestionViewProps) {
        super(props);

        this.state = {
            documentIdFilter: "",
            startTime: "",
            endTime: "",
            externalDataFilter: "",
            cV: "",
            enableSearch: 0,
            bigId:"",
            cvBybigId:"",
            sessionFilter:""
        };

        this.handleClick = this.handleClick.bind(this);
    }

    searchBigId=()=>{
            const {documentIdFilter,cV} = this.state

            this.setState({bigId:documentIdFilter})
            this.setState({cvBybigId:cV})
    }

    handleSessionClick=()=> {
            const {startTime,endTime,cV,sessionFilter,enableSearch} =this.state
            console.log('cv',cV)
            this.setState({startTime:startTime})
            this.setState({endTime:endTime})
            this.setState({cV:cV})
            this.setState({sessionFilter:sessionFilter})
            this.setState({enableSearch: this.state.enableSearch + 1})
    }
    render() {
        return (
            <div style={LayoutStyles.Main}>
                {this.props.ingestionType!=IngestionType.BIGID?(
                <Stack
                    horizontalAlign="start"
                    verticalAlign="start"
                    verticalFill
                    styles={stackStyles}
                    tokens={stackTokens}
                >
                    <Text variant="xxLarge" styles={boldStyle}>
                        Find details of your ingestion session.
                    </Text>
                    <Text variant="large">
                        For a guide on how to find the session id, please go to TSG link.{" "}
                    </Text>
                    <Stack horizontal wrap>
                    {this.props.ingestionType == IngestionType.MANIFEST?(
                      <></>
                    ):(
                        <StackItem grow tokens={calendarWrapperTokens} styles={stackItemStyles}>
                            <TextField
                                label="Document Id"
                                placeholder="Please enter DocumentId here"
                                onChange={(e, val) => {
                                    this.setState({ documentIdFilter: val || "" });
                                }}
                            ></TextField>
                        </StackItem>)}
                        {this.props.ingestionType == IngestionType.MANIFEST?<></>:(
                        <StackItem grow tokens={calendarWrapperTokens}>
                            <TextField
                                label="ExternalDataFilter"
                                onChange={(e, val) => {
                                    this.setState({ externalDataFilter: val || "" });
                                }}
                            ></TextField>
                        </StackItem>)}
                        <StackItem grow tokens={calendarWrapperTokens}>
                            <TextField
                                label="Correlation Vector"
                                onChange={(e, val) => {
                                    this.setState({ cV: val || "" });
                                }}
                            ></TextField>
                        </StackItem>
                        {this.props.ingestionType == IngestionType.INGESTION?<></>:(
                        <StackItem grow tokens={calendarWrapperTokens}>
                            <TextField
                                label="Session Id"
                                onChange={(e, val) => {
                                    this.setState({ sessionFilter: val || "" });
                                }}
                            ></TextField>
                        </StackItem>)}
                        <Suspense fallback={null}>
                            <DateRangePicker
                                onClearDate={() => {
                                    this.setState({ startTime: "" });
                                    this.setState({ endTime: "" });
                                }}
                                onApplyDate={(
                                    startDate: Date | undefined,
                                    endDate: Date | undefined
                                ) => {
                                    this.setState({ startTime: startDate === undefined ? "" : VF.onFormatDate(startDate) });
                                    this.setState({ endTime: endDate === undefined ? "" : VF.onFormatDate(endDate) });
                                }}
                            />
                        </Suspense>
                    </Stack>
                    { this.props.ingestionType == IngestionType.MANIFEST?(
                    <>
                    <PrimaryButton text="Search" onClick={this.handleSessionClick} />
                    <ManifestSessionListView sessionId={""}
                    searchList={{sessionId: this.state.sessionFilter,
                        enabled: this.state.enableSearch,
                        startDate: this.state.startTime,
                        endDate: this.state.endTime,
                        cV: this.state.cV
                    }} />
                    </>
                    
                    ):(
                    <>
                    <PrimaryButton text="Search" onClick={this.handleClick} />
                    <SessionListView documentId={""} listName={listNameEnum.sessionListView}
                    searchList={{
                        enabled: this.state.enableSearch,
                        documentId: this.state.documentIdFilter,
                        startDate: this.state.startTime,
                        endDate: this.state.endTime,
                        externalDataFilter: this.state.externalDataFilter,
                        cV: this.state.cV,
                    } as SearchListProps} />
                    </>
                    )}
                </Stack>):
                (
                    <Stack
                    horizontalAlign="start"
                    verticalAlign="start"
                    verticalFill
                    styles={stackStyles}
                    tokens={stackTokens}
                >
                    <Text variant="xxLarge" styles={boldStyle}>
                        Find details of your BigId ingestion session.
                    </Text>
                    <Stack horizontal wrap>
                        <StackItem grow tokens={calendarWrapperTokens} styles={stackItemStyles}>
                            <TextField
                                label="BigId"
                                placeholder="Please enter Big Id here"
                                onChange={(e, val) => {
                                    this.setState({ documentIdFilter: val || "" });
                                }}
                            ></TextField>
                        </StackItem>
                        <StackItem grow tokens={calendarWrapperTokens} styles={stackItemStyles}>
                        <TextField
                                label="CV"
                                placeholder="Please enter CV here"
                                onChange={(e, val) => {
                                    this.setState({ cV: val || "" });
                                }}
                            ></TextField>
                            </StackItem>
                    </Stack>
                    <PrimaryButton text="Search" onClick={this.searchBigId} />
                    <BigIdSessionView bigId={this.state.bigId} cv={this.state.cvBybigId} />
                </Stack>
                )
                }
            </div>
        );
    }
    
    handleClick(): void {
        console.log(`Searching.. documentid=${this.state.documentIdFilter.trim()}, StartTime=${this.state.startTime.trim()}, EndTime=${this.state.endTime.trim()}, externalDataFilter=${this.state.externalDataFilter.trim()}, cV=${this.state.cV.trim()}`);
        this.setState(() => (
            {
            documentIdFilter: this.state.documentIdFilter.trim(),
            startTime: this.state.startTime.trim(),
            endTime: this.state.endTime.trim(),
            externalDataFilter: this.state.externalDataFilter.trim(),
            cV: this.state.cV.trim(),
            enableSearch: this.state.enableSearch + 1,
        }));
    }

}
