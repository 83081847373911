import {
  IStackStyles,
  Spinner,
  Stack,
  IStackTokens,
  Link,
} from "@fluentui/react";
import { mergeStyles } from "@fluentui/react/lib/Styling";
import React, { useState, useEffect } from "react";
import { useApi, ApiParameters } from "../hooks/useApi";
import { OrchestrationEvent } from "../models/orchestrationEvent";
import devOpsApi, { GetEventsRequest } from "../services/devOpsApiClient";
import { StatusMessage, StatusMessageBar } from "../statusMessageBar";
import { SwatchColorPicker } from "@fluentui/react/lib/SwatchColorPicker";
import { Environment, getEnvironment } from "../../config/environmentConfig";
import { Session } from "../models/session";

const stackStyles: IStackStyles = {
  root: {
    width: 200,
    height: 575,
  },
};
const stackLineStyles: IStackStyles = {
  root: {
    width: 65,
    height: 575,
  },
};
const headingStackTokens: IStackTokens = { childrenGap: 20 };
const roundSpacingStackTokens: IStackTokens = {
  childrenGap: "20%",
  padding: "m 20px",
};
const lineSpacingStackTokens: IStackTokens = {
  childrenGap: "0%",
};
const itemAlignmentsStackStyles: IStackStyles = {
  root: {
    height: 60,
    float: "right",
  },
};
const itemAlignmentsStackTokens: IStackTokens = {
  childrenGap: 5,
  padding: 10,
};
const itemStyles: React.CSSProperties = {
  alignItems: "center",
  display: "flex",
  height: 190,
  justifyContent: "center",
  width: 100,
};
const greenRoundClass = mergeStyles({
  width: 160,
  height: 160,
  backgroundColor: "green",
  borderRadius: 80,
  textAlign: "center",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "white",
  fontSize: "14px",
  padding: 5,
});
const yellowRoundClass = mergeStyles({
  width: 160,
  height: 160,
  backgroundColor: "yellow",
  borderRadius: 80,
  textAlign: "center",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "black",
  fontSize: "14px",
  padding: 5,
});
const redRoundClass = mergeStyles({
  width: 160,
  height: 160,
  backgroundColor: "red",
  borderRadius: 80,
  textAlign: "center",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "white",
  fontSize: "14px",
  padding: 5,
});
const grayRoundClass = mergeStyles({
  width: 160,
  height: 160,
  backgroundColor: "gray",
  borderRadius: 80,
  textAlign: "center",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "white",
  fontSize: "14px",
  padding: 5,
});
const legendClass = mergeStyles({
  fontSize: 14,
  fontWeight: 400,
});
const colorCellsExample = [
  { id: "a", label: "Not Start", color: "gray" },
  { id: "b", label: "Started", color: "yellow" },
  { id: "c", label: "Success", color: "green" },
  { id: "d", label: "Failed", color: "red" },
];

export interface OverViewProps {
  documentId: string;
  sessionId: string;
  session: Session;
}

export function DataProcessorView(props: OverViewProps) {
  const [events, updateEvents] = React.useState<OrchestrationEvent[]>([]);
  const [apiParameters, updateApiParameters] = useState<ApiParameters[]>([]);
  const [ready, response, isLoading, error, execute] = useApi<any>();
  const [statusMessage, setStatusMessage] = useState<StatusMessage | null>(
    null
  );
  const sessionMonitoringEvent = "sessionmonitoringevent";
  const [eventsClass, setEventsClass] = React.useState<string[]>([]);
  const [costTime, setCostTime] = React.useState<string[]>([]);
  const [intervalTime, setIntervalTime] = React.useState<string[]>([]);
  const enviromentName = +getEnvironment();

  useEffect(() => {
    const request = {
      productId: props.documentId,
      revisionId: props.sessionId,
    } as GetEventsRequest;

    updateApiParameters([devOpsApi.getEvents(request)]);
  }, [props]);

  useEffect(() => {
    if (ready) {
      execute(apiParameters);
    }
  }, [apiParameters, ready]);

  useEffect(() => {
    if (response) {
      const documents = response.TrackingEvents as OrchestrationEvent[];
      if (documents.length === 0) {
        return;
      }
      const sortDocuments = documents
        .slice()
        .sort(
          (pre, after) =>
            new Date(pre.eventTime.toString()).getTime() -
            new Date(after.eventTime.toString()).getTime()
        );
      updateEvents(sortDocuments);

      if (statusMessage) {
        setStatusMessage(null);
      }
    }
  }, [response]);

  useEffect(() => {
    if (error) {
      setStatusMessage(error);
    }
  }, [error]);

  useEffect(() => {
    if (events.length > 0) {
      updateEventsInfo();
    }
  }, [events]);

  function getDateDiff(startDate: Date, endDate: Date, nowDate: Date) {
    var sd = Date.parse(startDate.toString());
    var ed = Date.parse(endDate.toString());
    var now = Date.parse(nowDate.toString());
    if (sd === now || ed === now) {
      return "n/a";
    }
    var drr = ed - sd;
    var day = parseInt((drr / (24 * 60 * 60 * 1000)).toString());
    var hours = parseInt(
      ((drr % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000)).toString()
    );
    var minutes = parseInt(((drr % (60 * 60 * 1000)) / (60 * 1000)).toString());
    var seconds = parseInt(((drr % (60 * 1000)) / 1000).toString());
    var result =
      (day === 0 ? "" : day + "d") +
      (hours === 0 ? "" : hours + "h") +
      (minutes === 0 ? "" : minutes + "m") +
      (seconds === 0 ? "" : seconds + "s");
    return result === "" ? "0s" : result;
  }

  function updateEventsInfo() {
    let classArray: string[] = [
      grayRoundClass,
      grayRoundClass,
      grayRoundClass,
      grayRoundClass
    ];
    let timeArray: string[] = ["", "", "", ""];
    let intervalArray: string[] = ["", "", ""];
    const sessionMonitoringEvents = events.filter(
      (e) => e.eventType.toLocaleLowerCase() === sessionMonitoringEvent
    );
    const now = new Date();
    let ingestionApiStartTime: Date = now;
    let ingestionApiEndTime: Date = now;
    let ingestionApiRecentEndTime: Date = now;
    let archivingStartTime: Date = now;
    let archivingEndTime: Date = now;
    let archivingRecentEndTime: Date = now;
    let syncCheckStartTime: Date = now;
    let syncCheckEndTime: Date = now;
    let syncCheckRecentEndTime: Date = now;
    let evictionStartTime: Date = now;
    let evictionEndTime: Date = now;
    let evictionRecentEndTime: Date = now;
    sessionMonitoringEvents.forEach((element) => {
      let eventName = JSON.parse(
        JSON.stringify(element.data)
      ).EventName.toString();
      let subState = JSON.parse(
        JSON.stringify(element.data)
      ).SubState.toString();
      switch (eventName) {
        case "IngestionApiNewRequestEvent":
          if (subState === "Failed") classArray[0] = redRoundClass;
          else if (
            subState === "Success" &&
            classArray[0] !== redRoundClass &&
            classArray[0] !== greenRoundClass
          )
            classArray[0] = yellowRoundClass;
          if (
            Date.parse(ingestionApiStartTime.toString()) ===
            Date.parse(now.toString())
          )
            ingestionApiStartTime = element.eventTime;
          break;
        case "IngestionApiRequestCompleteEvent":
          if (subState === "Failed" && classArray[0] !== greenRoundClass)
            classArray[0] = redRoundClass;
          if (subState === "Success") classArray[0] = greenRoundClass;
          if (
            Date.parse(ingestionApiEndTime.toString()) ===
            Date.parse(now.toString())
          )
            ingestionApiEndTime = element.eventTime;
          ingestionApiRecentEndTime = element.eventTime;
          break;
        case "ArchivingNewRequestEvent":
          if (subState === "Failed") classArray[1] = redRoundClass;
          else if (
            subState === "Success" &&
            classArray[1] !== redRoundClass &&
            classArray[1] !== greenRoundClass
          )
            classArray[1] = yellowRoundClass;
          if (
            Date.parse(archivingStartTime.toString()) ===
            Date.parse(now.toString())
          )
            archivingStartTime = element.eventTime;
          break;
        case "ArchivingRequestCompleteEvent":
          if (subState === "Failed" && classArray[1] !== greenRoundClass)
            classArray[1] = redRoundClass;
          if (subState === "Success" || subState === "Skipped")
            classArray[1] = greenRoundClass;
          if (
            Date.parse(archivingEndTime.toString()) ===
            Date.parse(now.toString())
          )
            archivingEndTime = element.eventTime;
          archivingRecentEndTime = element.eventTime;
          break;
        case "SyncCheckNewRequestEvent":
          if (subState === "Failed") classArray[2] = redRoundClass;
          else if (
            subState === "Success" &&
            classArray[2] !== redRoundClass &&
            classArray[2] !== greenRoundClass
          )
            classArray[2] = yellowRoundClass;
          if (
            Date.parse(syncCheckStartTime.toString()) ===
            Date.parse(now.toString())
          )
            syncCheckStartTime = element.eventTime;
          break;
        case "SyncCheckRequestCompleteEvent":
          if (subState === "Failed" && classArray[2] !== greenRoundClass)
            classArray[2] = redRoundClass;
          if (subState === "Success" || subState === "Skipped")
            classArray[2] = greenRoundClass;
          if (
            Date.parse(syncCheckEndTime.toString()) ===
            Date.parse(now.toString())
          )
            syncCheckEndTime = element.eventTime;
          syncCheckRecentEndTime = element.eventTime;
          break;
        case "EvictionNewRequestEvent":
          if (subState === "Failed") classArray[3] = redRoundClass;
          else if (
            subState === "Success" &&
            classArray[3] !== redRoundClass &&
            classArray[3] !== greenRoundClass
          )
            classArray[3] = yellowRoundClass;
          if (
            Date.parse(evictionStartTime.toString()) ===
            Date.parse(now.toString())
          )
            evictionStartTime = element.eventTime;
          break;
        case "EvictionRequestCompleteEvent":
          if (subState === "Failed" && classArray[3] !== greenRoundClass)
            classArray[3] = redRoundClass;
          if (subState === "Success" || subState === "Skipped")
            classArray[3] = greenRoundClass;
          if (
            Date.parse(evictionEndTime.toString()) ===
            Date.parse(now.toString())
          )
            evictionEndTime = element.eventTime;
          evictionRecentEndTime = element.eventTime;
          break;
        default:
          break;
      }
    });
    timeArray[0] = getDateDiff(
      ingestionApiStartTime,
      ingestionApiRecentEndTime,
      now
    );
    timeArray[1] = getDateDiff(
      archivingStartTime,
      archivingRecentEndTime,
      now
    );
    timeArray[2] = getDateDiff(
      syncCheckStartTime,
      syncCheckRecentEndTime,
      now
    );
    timeArray[3] = getDateDiff(
      evictionStartTime,
      evictionRecentEndTime,
      now
    );
    intervalArray[0] = getDateDiff(
      ingestionApiEndTime,
      archivingStartTime,
      now
    );
    intervalArray[1] = getDateDiff(
      archivingEndTime,
      syncCheckStartTime,
      now
    );
    intervalArray[2] = getDateDiff(
      syncCheckEndTime,
      evictionStartTime,
      now
    );
    setCostTime(timeArray);
    setIntervalTime(intervalArray);
    setEventsClass(classArray);
  }

  const generateGenevaLink = (
    session: Session | undefined,
    serviceName: string
  ) => {
    if (session) {
      const namespaces =
        +getEnvironment() === Environment.PROD
          ? "OfferStoreIngestionProd"
          : "OfferStoreIngestionInt";

      return (
        `https://portal.microsoftgeneva.com/logs/dgrep?be=DGrep&time=${session.createdDateTime}&offset=%2B1&offsetUnit=Days&UTC=true` +
        `&ep=Diagnostics%20PROD&ns=${namespaces}&en=CustomEvents,Log` +
        `&conditions=[["cV","contains","${encodeURIComponent(encodeURI(
          session.correlationVector.substring(
            0,
            session.correlationVector.indexOf(".")
          )
        ))}"],["ext_cloud_role","%3D%3D","${serviceName}"]]`
      );
    }

    return "";
  };

  return (
    <div>
      {isLoading && <Spinner label="Loading..." />}
      {eventsClass.length === 4 && (
        <Stack horizontal disableShrink tokens={headingStackTokens}>
          <Stack
            styles={stackStyles}
            tokens={roundSpacingStackTokens}
            verticalAlign="center"
          >
            <Link
              href={generateGenevaLink(props.session, "offerstoreingestionapi")}
              title="Go to Geneva"
              target="_blank"
            >
              <div className={eventsClass[0]}>
                Ingested
                <br />
                {costTime[0]}
              </div>
            </Link>
          </Stack>
          <Stack
            styles={stackLineStyles}
            tokens={lineSpacingStackTokens}
            verticalAlign="center"
          >
            <div style={itemStyles}>
              <svg>
                <defs>
                  <marker
                    id="markerArrow"
                    markerWidth="12"
                    markerHeight="12"
                    refX="9"
                    refY="6"
                    orient="auto"
                  >
                    <path
                      d="M2,2 L2,11 L10,6 L2,2"
                      style={{ fill: "rgb(120,120,120)" }}
                    />
                  </marker>
                </defs>
                <line
                  x1="0"
                  y1="80"
                  x2="100"
                  y2="80"
                  style={{
                    stroke: "rgb(120,120,120)",
                    strokeWidth: 2,
                  }}
                  markerEnd="url(#markerArrow)"
                />
                <text x="40" y="65" fill="gray">
                  {intervalTime[0]}
                </text>
              </svg>
            </div>
          </Stack>
          <Stack
            styles={stackStyles}
            tokens={roundSpacingStackTokens}
            verticalAlign="center"
          >
            <Link
              href={generateGenevaLink(
                props.session,
                "offerstoredataprocessor"
              )}
              title="Go to Geneva"
              target="_blank"
            >
              <div className={eventsClass[1]}>
                Replicated
                <br />
                {costTime[1]}
              </div>
            </Link>
          </Stack>
          <Stack
            styles={stackLineStyles}
            tokens={lineSpacingStackTokens}
            verticalAlign="center"
          >
            <div style={itemStyles}>
              <svg>
                <line
                  x1="0"
                  y1="80"
                  x2="100"
                  y2="80"
                  style={{
                    stroke: "rgb(120,120,120)",
                    strokeWidth: 2,
                  }}
                  markerEnd="url(#markerArrow)"
                />
                <text x="40" y="65" fill="gray">
                  {intervalTime[1]}
                </text>
              </svg>
            </div>
          </Stack>
          <Stack
            styles={stackStyles}
            tokens={roundSpacingStackTokens}
            verticalAlign="center"
          >
            <Link
              href={generateGenevaLink(
                props.session,
                "offerstoredataprocessor"
              )}
              title="Go to Geneva"
              target="_blank"
            >
              <div className={eventsClass[2]}>
                Distributed
                <br />
                {costTime[2]}
              </div>
            </Link>
          </Stack>
          <Stack
            styles={stackLineStyles}
            tokens={lineSpacingStackTokens}
            verticalAlign="center"
          >
            <div style={itemStyles}>
              <svg>
                <line
                  x1="0"
                  y1="80"
                  x2="100"
                  y2="80"
                  style={{
                    stroke: "rgb(120,120,120)",
                    strokeWidth: 2,
                  }}
                  markerEnd="url(#markerArrow)"
                />
                <text x="15" y="65" fill="gray">
                  {intervalTime[2]}
                </text>
              </svg>
            </div>
          </Stack>
          <Stack
            styles={stackStyles}
            tokens={roundSpacingStackTokens}
            verticalAlign="center"
          >
            <Link
              href={generateGenevaLink(
                props.session,
                "offerstoredataprocessor"
              )}
              title="Go to Geneva"
              target="_blank"
            >
              <div className={eventsClass[3]}>
                Archived
                <br />
                {costTime[3]}
              </div>
            </Link>
          </Stack>
          <Stack
            horizontal
            disableShrink
            styles={itemAlignmentsStackStyles}
            tokens={itemAlignmentsStackTokens}
          >
            <Stack.Item align="center">
              <span className={legendClass}>Legend:</span>
            </Stack.Item>
            <Stack.Item align="center">
              <SwatchColorPicker
                columnCount={4}
                cellShape={"circle"}
                cellHeight={30}
                cellWidth={30}
                cellBorderWidth={2}
                colorCells={colorCellsExample}
                aria-labelledby={"colorpicker-grid"}
              />
            </Stack.Item>
          </Stack>
        </Stack>
      )}
      <StatusMessageBar message={statusMessage} isMultiline />
    </div>
  );
}
